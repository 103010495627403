import axios from './axios'

export default {
    actions: {
        postProducts(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('products', data)
                    .then(() => {
                        resolve()
                        context.dispatch('fetchProducts')
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchProducts(context, data = {}) {
            let url = 'products?'

            if (data.page) {
                url += 'page=' + data.page
            } else {
                url += 'pagination=false'
            }

            if (data.name) {
                url += '&name=' + data.name
            }


            return new Promise((resolve, reject) => {
                axios
                    .get(url)
                    .then((response) => {
                        let products = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems'],
                        }

                        context.commit('updateProducts', products)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchProduct(context, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get('products/' + id)
                    .then((response) => {
                        context.commit('updateProduct', response.data)
                        resolve(response.data)
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        putProduct(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put('products/' + data.id, data)
                    .then(() => {
                        resolve()
                        context.dispatch('fetchProducts')
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        cancelProduct(context, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete('products/' + id)
                    .then(() => {
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
    },
    mutations: {
        updateProducts(state, products) {
            state.products = products
        },
        updateProduct(state, product) {
            state.product = product
        }
    },
    state: {
        products: {
            models: [],
            totalItems: 0,
        },
        product: null
    },
    getters: {
        getProducts(state) {
            return state.products.models
        },
        getProductTotalItems(state) {
            return state.products.totalItems
        }
    }
}