import store from "@/plugins/vuex/store";

export default {
    inomchi() {
        return this.hasAccessToUnderCeoLevel('ROLE_INOM_AKFASI')
    },
    canStoreKeeping() {
        return this.hasAccessToUnderCeoLevel('ROLE_STOREKEEPER')
    },
    canManageWarehouse() {
        return this.hasAccessToUnderCeoLevel('ROLE_WAREHOUSE_MANAGER')
    },
    canPrice() {
        return this.hasAccessToUnderCeoLevel('ROLE_ORDER_PRICER')
    },
    canStart() {
        return this.hasAccessToUnderCeoLevel('ROLE_FIRST_EMPLOYEE_WORKSHOP')
    },
    canStop() {
        return this.hasAccessToUnderCeoLevel('ROLE_SECOND_EMPLOYEE_WORKSHOP')
    },
    canTakeOrder() {
        return this.hasAccessToUnderCeoLevel('ROLE_ORDER_TAKER')
    },
    hasRoleCeo() {
        return this.hasRole('ROLE_CEO')
    },
    hasRoleAdmin() {
        return this.hasRole('ROLE_ADMIN')
    },
    hasRoleCeoOrAdmin() {
        if (this.hasRoleCeo()) {
            return true;
        }

        return this.hasRoleAdmin();
    },
    hasRole(roleName) {
        return store.getters.getUserRoles.includes(roleName)
    },
    hasAccessToUnderCeoLevel(roleName) {
        if (this.hasRoleCeoOrAdmin()) {
            return true
        }

        return this.hasRole(roleName)
    },
}
