<template>
    <div class='dashboard'>
        <div class="dashboard-nav">
            <header>
                <router-link to="/" class="brand-logo">
                    <span>Nav Glass </span>
                </router-link>
            </header>
            <nav class="dashboard-nav-list">

                <div v-if="access.hasRoleCeoOrAdmin()" class="dropdown">
                    <a style="background: none; border: none;"
                       class="btn btn-secondary dropdown-toggle dashboard-nav-item" role="button"
                       data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-landmark"></i>
                        Ombor
                    </a>
                    <ul style="background-color: #373193; width: 100%; text-align: end;" class="dropdown-menu">
                        <li v-if="access.hasRoleCeoOrAdmin()">
                            <router-link class="dropdown-item" to="/barn">Ombor(Склад)</router-link>
                        </li>
                        <li v-if="access.hasRoleCeoOrAdmin()">
                            <router-link class="dropdown-item" to="/products">Mahsulotlar</router-link>
                        </li>
                        <li v-if="access.hasRoleCeoOrAdmin()">
                            <router-link class="dropdown-item" to="/transitions">Tranzaksiyalar</router-link>
                        </li>
                        <li v-if="access.canManageWarehouse()">
                            <router-link class="dropdown-item" to="/buyurtma-page">Buyurtmalar</router-link>
                        </li>
                    </ul>
                </div>

                <div class="dropdown">
                    <a style="background: none; border: none;"
                       class="btn btn-secondary dropdown-toggle dashboard-nav-item" role="button"
                       data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-photo-video"></i>
                        Zakaz
                    </a>
                    <ul style="background-color: #373193; width: 100%; text-align: end;" class="dropdown-menu">
                        <li v-if=" access.canTakeOrder()">
                            <router-link class="dropdown-item" to="/comers-page">Savdo</router-link>
                        </li>
                        <li v-if="access.canTakeOrder() || access.canPrice()">
                            <router-link class="dropdown-item" to="/draw">Zakaz</router-link>
                        </li>
                        <li v-if="access.canTakeOrder() || access.canPrice()">
                            <router-link class="dropdown-item" to="/shablon">Shablon chizish</router-link>
                        </li>
                        <li v-if="access.canPrice() || access.canTakeOrder()">
                            <router-link class="dropdown-item" to="/pricing-order">{{ access.canPrice() ? 'Narxlash' : 'Tahrirlash' }}</router-link>
                        </li>
                        <li v-if="access.hasRoleCeoOrAdmin() || access.canTakeOrder()">
                            <router-link class="dropdown-item" to="/progress-page">Yig'ilayotgan zakazlar</router-link>
                        </li>
                        <li v-if="access.hasRoleCeoOrAdmin()">
                            <router-link class="dropdown-item" to="/orders-page">Tranzaksiyalar (Savdo)</router-link>
                        </li>
                        <li v-if="access.canStart()">
                            <router-link class="dropdown-item" to="/start-list">StartList</router-link>
                        </li>
                        <li v-if="access.canStart()">
                            <router-link class="dropdown-item" to="/started-list">StartedList</router-link>
                        </li>
                        <li v-if="access.canStop()">
                            <router-link class="dropdown-item" to="/finish-list">FinishList</router-link>
                        </li>
                    </ul>
                </div>

                <div v-if="access.inomchi()" class="dropdown">
                    <a style="background: none; border: none;"
                       class="btn btn-secondary dropdown-toggle dashboard-nav-item" role="button"
                       data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-photo-video"></i>
                        Inom Akfasi
                    </a>
                    <ul style="background-color: #373193; width: 100%; text-align: end;" class="dropdown-menu">
                        <li>
                            <router-link class="dropdown-item" to="/start-inom">Start List</router-link>
                        </li>
                        <li>
                            <router-link class="dropdown-item" to="/started-inom">Started List</router-link>
                        </li>
                        <li>
                            <router-link class="dropdown-item" to="/finish-inom">Finish List</router-link>
                        </li>
                    </ul>
                </div>

                <div v-if="access.hasRoleCeoOrAdmin()" class="dropdown">
                    <a style="background: none; border: none;"
                       class="btn btn-secondary dropdown-toggle dashboard-nav-item" role="button"
                       data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-chart-column"></i>
                        Korxona
                    </a>
                    <ul style="background-color: #373193; width: 100%; text-align: end;" class="dropdown-menu">
                        <li>
                            <router-link class="dropdown-item" to="/farm-page">Statistika</router-link>
                        </li>
                        <li>
                            <router-link class="dropdown-item" to="/outpage">Kirim chiqim</router-link>
                        </li>
                        <li>
                            <router-link class="dropdown-item" to="/broken-glass">Singan shishalar</router-link>
                        </li>
                    </ul>
                </div>

                <router-link v-if="access.hasRoleCeoOrAdmin()" to="/worker" class="dashboard-nav-item">
                    <i class="fas fa-user"></i>
                    Ishchilar
                </router-link>
                <router-link v-if=" access.canTakeOrder()" to="/clientpage" class="dashboard-nav-item">
                    <i class="fas fa-users"></i>
                    Mijozlar <br> (клиенты)
                </router-link>

                <div v-if="access.canStoreKeeping()" class='dashboard-nav-dropdown'>
                    <router-link to="/postavka-page" class="dashboard-nav-item">
                        <i class="fas fa-truck-fast"></i>
                        Yetkazib beruvchi
                    </router-link>
                </div>

            </nav>
        </div>
    </div>
</template>

<script>

import access from "@/mixin/functions/access";

export default {
    name: "SideBar",
    computed: {
        access() {
            return access
        }
    }
}
</script>

<style scoped>
li {
    list-style-type: none;
}

.dropdown-item {
    transition: 1s;
}

.dropdown-item:hover {
    background-color: #0458eb;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

a {
    color: #3f84fc;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0458eb;
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Nunito", sans-serif;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}


.dashboard-nav {
    min-width: 238px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    background-color: #373193;
}


.dashboard-nav header {
    min-height: 84px;
    padding: 8px 27px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.dashboard-nav header .menu-toggle {
    display: none;
    margin-right: auto;
}

.dashboard-nav a {
    color: #515151;
}

.dashboard-nav a:hover {
    text-decoration: none;
}

.dashboard-nav {
    background-color: #443ea2;
}

.dashboard-nav a {
    color: #fff;
}

.brand-logo {

    font-weight: bold;
    font-size: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: #515151;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.brand-logo:focus,
.brand-logo:active,
.brand-logo:hover {
    color: #dbdbdb;
    text-decoration: none;
}

.brand-logo i {
    color: #d2d1d1;
    font-size: 27px;
    margin-right: 10px;
}

.dashboard-nav-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.dashboard-nav-item {
    min-height: 56px;
    padding: 8px 20px 8px 70px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.02em;
    transition: ease-out 0.5s;
}

.dashboard-nav-item i {
    width: 36px;
    font-size: 19px;
    margin-left: -40px;
}

.dashboard-nav-item:hover {
    background: rgba(255, 255, 255, 0.04);
}

.dashboard-nav-dropdown {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.dashboard-nav-dropdown.show {
    background: rgba(255, 255, 255, 0.04);
}

.dashboard-nav-dropdown.show > .dashboard-nav-dropdown-toggle {
    font-weight: bold;
}

.dashboard-nav-dropdown.show > .dashboard-nav-dropdown-toggle:after {
    -webkit-transform: none;
    -o-transform: none;
    transform: none;
}

.dashboard-nav-dropdown.show > .dashboard-nav-dropdown-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.dashboard-nav .dashboard-nav-dropdown-toggle:after {
    border-top-color: rgba(255, 255, 255, 0.72);
}

.dropdown-item {
    background-color: rgba(255, 255, 255, 0.04);
    color: black;
}

@media (max-width: 1000px) {
    .dashboard {
        display: none;
    }
}
</style>
