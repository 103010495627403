<template>
    <div class="container-fluid">
        <div class="row">

            <div v-if="getAccessToken.length > 0" class="col-12 col-md-2 col-lg-2">
                <!--side bar start-->
                <SideBar/>
                <!--side bar end-->
            </div>

            <div class="p-4 col-12 col-md-10 col-lg-10">
                <!--kontent start-->
                <CabinetFixed v-if="!isDisabled"/>
                <router-view/>
                <!--kontent end-->
            </div>

        </div>
    </div>
</template>

<script>
import SideBar from "@/components/SideBar";
import CabinetFixed from "@/pages/login/CabinetFixed";
import {mapActions, mapGetters} from "vuex";
import store from "@/plugins/vuex/store";
import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import axios from "@/plugins/vuex/axios";
import router from "@/plugins/router";

const firebaseConfig = {
    apiKey: "AIzaSyDo0_4mkvWpZWK0fjci5akIiuURBuwdERM",
    authDomain: "nav-glass-7bbe3.firebaseapp.com",
    projectId: "nav-glass-7bbe3",
    storageBucket: "nav-glass-7bbe3.appspot.com",
    messagingSenderId: "1010191699927",
    appId: "1:1010191699927:web:d8411ecf22baf062684592"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
getToken(messaging, {vapidKey: "BBkRk_BNK_FrS-CK9kTOUHdXOUlnTUH1226S4sJqzaohFY672j6qbr7JnvvgDIAJ33vOZ5lY8tEzxYs2XqdC5iQ"})
    .then((currentToken) => {
        if (currentToken) {
            // Send the token to your server and update the UI if necessary
            axios
                .post('users/fire_token', {'tokenData': currentToken})
            // ...
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
        }
    }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
})

onMessage(messaging, (payload) => {
    console.log("Message received. ", payload);
    let audio = new Audio('./sound.mp3')
    audio.play()
    alert(payload.notification.body)
    router.go(0)
});

export default {
    name: 'App',
    components: {SideBar, CabinetFixed},
    computed: {
        ...mapGetters(['getAccessToken']),
        isDisabled() {
            return store.state.isDisabled || this.$route.path === '/login'
        }
    },
    methods: {
        ...mapActions(['fetchUserAboutMe'])
    },
    mounted() {
        if (this.getAccessToken.length === 0) {
            this.$router.push('/login')
        }

        this.fetchUserAboutMe()
    }
}

</script>

<style type="text/css">
iframe {
    border: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}
</style>
