import {createStore} from 'vuex'
import person from "@/plugins/vuex/person"
import user from "@/plugins/vuex/user"
import imageUpload from "@/plugins/vuex/local/imageUpload";
import localToken from "@/plugins/vuex/local/localToken";
import position from "@/plugins/vuex/position";
import shablon from "@/plugins/vuex/shablon";
import customer from "@/plugins/vuex/customer";
import product from "@/plugins/vuex/product";
import inoutcome from "@/plugins/vuex/inoutcome";
import purchase from "@/plugins/vuex/purchase";
import productInWarehouses from "@/plugins/vuex/productInWarehouses";
import sale from "@/plugins/vuex/sale";
import supplier from "@/plugins/vuex/supplier";

export default createStore({
    modules: {
        person,
        position,
        user,
        localToken,
        imageUpload,
        shablon,
        customer,
        product,
        inoutcome,
        purchase,
        productInWarehouses,
        sale,
        supplier
    },
    state: {
        isDisabled: false,
        position: ''
    },
    getters: {
        ring() {
            let audio = new Audio('./sound.mp3')
            audio.play()
        }
    }
})