import axios from './axios'
export default {
    actions: {
        fetchProductInWarehouses(context, data = {}) {
            let url = 'product_in_warehouses?';

            if (data.page) {
                url += 'page=' + data['page']
            } else {
                url += 'pagination=false'
            }

            if (data.name) {
                url += '&product.name=' + data.name
            }

            if (data.isWarehouse) {
                url += '&product.canSell=true'
            }


            return new Promise((resolve, reject) => {
                axios
                    .get(url)
                    .then((response) => {
                        let products = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems'],
                        }

                        context.commit('updateProductInWarehouses', products)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        putProductInWarehouse(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put('product_in_warehouses/' + data.id, data)
                    .then(() => {
                        resolve()
                        context.dispatch('fetchProductInWarehouses')
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchBrokenGlasses(context) {
            return new Promise((resolve, reject) => {
                axios
                    .get('broken_glasses?order[id]=desc')
                    .then((response) => {
                        let products = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems'],
                        }

                        context.commit('updateBrokenGlasses', products)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
    },
    mutations: {
        updateProductInWarehouses(state, productInWarehouses) {
            state.productInWarehouses = productInWarehouses
        },
        updateBrokenGlasses(state, productInWarehouses) {
            state.brokenGLasses = productInWarehouses
        }
    },
    state: {
        productInWarehouses:{
            models: [],
            totalItems: 0,
        },
        brokenGLasses:{
            models: [],
            totalItems: 0,
        }
    },
    getters: {
        getProductInWarehouses(state) {
            return state.productInWarehouses.models
        },
        getProductInWarehouseTotalItems(state) {
            return state.productInWarehouses.totalItems
        },
        getBrokenGlasses(state) {
            return state.brokenGLasses.models
        }
    }
}