import axios from './axios'
import localToken from "@/plugins/vuex/local/localToken";

export default {
    actions: {
        fetchToken(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('users/auth', data)
                    .then((response) => {
                        resolve()
                        context.commit('updateToken', response.data)
                        context.dispatch('fetchUserAboutMe')
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        fetchUserAboutMe(context) {
            return new Promise((resolve, reject) => {
                axios
                    .post('users/about_me', {})
                    .then((response) => {
                        context.commit('updateUser', response.data)
                        resolve(response.data)
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        sendSMS(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('users/sms', data)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        fetchRequestPassword(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('users/request_reset_password', data)
                    .then(() => {
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        fetchChangePassword(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('users/change_password', data)
                    .then(() => {
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        fetchUpdateUser(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('users/update', data)
                    .then(() => {
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        logOut(context) {
            context.commit("clearTokens")
        },
        fetchRefreshToken(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        '/users/auth/refreshToken',
                        {refreshToken: ctx.getters.getRefreshToken}
                    )
                    .then(response => {
                        ctx.commit('updateToken', response.data)
                        resolve()
                    })
                    .catch((reason) => {
                        reject(reason)
                    })
            })
        }
    },
    mutations: {
        updateToken(state, data) {
            state.accessToken = data.accessToken
            state.refreshToken = data.refreshToken

            localToken.setAccess(data.accessToken)
            localToken.setRefresh(data.refreshToken)
        },
        updateUser(state, data) {
            state.aboutMe = data.user
            state.currency = data.currency
            localStorage.setItem('user', JSON.stringify(data.user))
        },
        clearTokens(state) {
            state.accessToken = ''
            state.refreshToken = ''
            state.aboutMe = null

            localToken.clear()
            localStorage.setItem('user', '')
        }
    },
    state: {
        accessToken: localToken.getAccess(),
        refreshToken: localToken.getRefresh(),
        aboutMe: null,
        currency: 1
    },
    getters: {
        getUser(state) {
            return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))?.user : state.aboutMe?.user
        },
        getUserAboutMe(state) {
            return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : state.aboutMe
        },
        getUserName(state) {
            return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))?.givenName : state.aboutMe?.givenName
        },
        isAuthenticated(state, getters) {
            return getters.getAccessToken.length > 0
        },
        getAccessToken(state) {
            return state.accessToken
        },
        getRefreshToken(state) {
            return state.refreshToken
        },
        getUserRoles(state, getters) {
            return getters.getUser?.roles ?? []
        },
        getCurrency(state) {
            return state.currency
        }
    },
}