import {createRouter, createWebHistory} from "vue-router";
import store from "@/plugins/vuex/store";
import access from "@/mixin/functions/access";


const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next();
        return
    }

    next('/login')
}

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        if (store.getters.getUserRoles.length === 0 || to.meta.hasAccess()) {
            next()
        } else {
            alert('Access denied')
            from()
        }

        return
    }

    next('/login')
}

const routes = [
    {
        path: '/',
        component: () => import('@/components/HomePage.vue'),
        meta: {category: 'home', hasAccess: () => true},
    },
    {
        path: '/login',
        component: () => import('@/pages/login/LoginPage.vue'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/barn',
        component: () => import('@/pages/BarnPage.vue'),
        meta: {category: 'barn', hasAccess: () => access.canStoreKeeping()},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/plus',
        component: () => import('@/pages/BarnPlus.vue')
    },
    {
        path: '/print/:id',
        component: () => import('@/components/PrintPage.vue')
    },
    {
        path: '/worker',
        component: () => import('@/pages/WorkerPage.vue'),
        meta: {category: 'barn', hasAccess: () => access.canStoreKeeping()},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/workerplus',
        component: () => import('@/pages/WorkerPlus.vue'),
        meta: {category: 'barn', hasAccess: () => access.canStoreKeeping()},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/positionplus',
        component: () => import('@/pages/PositionPlus.vue'),
        meta: {category: 'barn', hasAccess: () => access.canStoreKeeping()},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/outpage',
        meta: {category: 'order', hasAccess: () => access.hasRoleCeoOrAdmin()},
        beforeEnter: ifAuthenticated,
        component: () => import('@/pages/OutPage.vue')
    },
    {
        path: '/outplus',
        meta: {category: 'order', hasAccess: () => access.hasRoleCeoOrAdmin()},
        beforeEnter: ifAuthenticated,
        component: () => import('@/pages/InOutCome.vue')
    },
    {
        path: '/clientpage',
        component: () => import('@/pages/ClientPage.vue'),
        meta: {category: 'barn', hasAccess: () => access.hasRoleCeoOrAdmin() || access.canTakeOrder()},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/clientplus',
        meta: {category: 'order', hasAccess: () => access.hasRoleCeoOrAdmin() || access.canTakeOrder()},
        component: () => import('@/pages/ClientPlus.vue')
    },
    {
        path: '/orderpage',
        meta: {category: 'order', hasAccess: () => access.canTakeOrder() || access.canPrice() || access.canStop},
        component: () => import('@/pages/OrderPage.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/draw',
        component: () => import('@/components/Draw.vue'),
        meta: {category: 'barn', hasAccess: () => access.canTakeOrder() || access.canPrice()},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/shablon',
        component: () => import('@/components/Shablon.vue'),
        meta: {category: 'barn', hasAccess: () => access.canTakeOrder() || access.canPrice()},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/calculator-page',
        component: () => import('@/components/CalculatorPage.vue'),
        meta: {category: 'barn', hasAccess: () => access.canPrice()},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/orders-page',
        meta: {category: 'order', hasAccess: () => access.hasRoleCeoOrAdmin()},
        component: () => import('@/components/OrdersPage.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/products',
        component: () => import('@/pages/ProductPage.vue'),
        meta: {category: 'barn', hasAccess: () => access.canStoreKeeping()},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/progress-page',
        component: () => import('@/components/ProgressPage.vue'),
        meta: {category: 'barn', hasAccess: () => access.hasRoleCeoOrAdmin() || access.canTakeOrder()},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/product-plus',
        component: () => import('@/pages/ProductPlus.vue'),
        meta: {category: 'barn', hasAccess: () => access.canStoreKeeping()},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/transitions',
        component: () => import('@/pages/TransitionPage.vue'),
        meta: {category: 'barn', hasAccess: () => access.hasRoleCeoOrAdmin()},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/password',
        component: () => import('@/pages/login/PasswordPage.vue'),
        beforeEnter: ifNotAuthenticated
    },
    {
        path: '/cabinet-fixed',
        component: () => import('@/pages/login/CabinetFixed.vue'),
        meta: {category: 'barn', hasAccess: () => true},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/cabinet-page',
        component: () => import('@/pages/login/CabinetPage.vue'),
        meta: {category: 'barn', hasAccess: () => true},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/postavka-page',
        component: () => import('@/pages/PostavkaPage.vue'),
        meta: {category: 'barn', hasAccess: () => access.canStoreKeeping()},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/post-plus',
        component: () => import('@/pages/PostavkaPlus.vue'),
        meta: {category: 'barn', hasAccess: () => access.canStoreKeeping()},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/send-telephone',
        component: () => import('@/pages/login/SendTelephone.vue'),
        beforeEnter: ifNotAuthenticated
    },
    {
        path: '/start-list',
        component: () => import('@/pages/StartList.vue'),
        meta: {category: 'barn', hasAccess: () => access.canStart()},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/started-list',
        component: () => import('@/pages/StartedList.vue'),
        meta: {category: 'barn', hasAccess: () => access.canStart()},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/start-order',
        component: () => import('@/pages/StartOrder.vue'),
        meta: {category: 'barn', hasAccess: () => access.canStart()},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/finish-list',
        component: () => import('@/pages/FinishList'),
        meta: {category: 'barn', hasAccess: () => access.canStop()},
        beforeEnter: ifAuthenticated
    },
    {
        path: '/pricing-order',
        meta: {category: 'order', hasAccess: () => access.canPrice() || access.canTakeOrder()},
        component: () => import('@/pages/PricingOrder.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/comers-page',
        meta: {
            category: 'order',
            hasAccess: () => access.canTakeOrder() || access.canPrice() || access.canManageWarehouse() || access.canStop
        },
        component: () => import('@/pages/ComersPage.vue'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/farm-page',
        beforeEnter: ifAuthenticated,
        meta: {category: 'order', hasAccess: () => access.hasRoleCeoOrAdmin()},
        component: () => import('@/pages/FarmPage.vue')
    },
    {
        path: '/buyurtma-page',
        component: () => import('@/pages/BuyurtmaPage.vue'),
        meta: {category: 'barn', hasAccess: () => access.canManageWarehouse()}
    },
    {
        path: '/broken-glass',
        component: () => import('@/pages/BrokenGlass.vue')
    },
    {
        path: '/start-inom',
        meta: {category: 'order', hasAccess: () => access.inomchi()},
        component: () => import('@/pages/StartInom.vue')
    },
    {
        path: '/started-inom',
        meta: {category: 'order', hasAccess: () => access.inomchi()},
        component: () => import('@/pages/StartedInom.vue')
    },
    {
        path: '/finish-inom',
        meta: {category: 'order', hasAccess: () => access.inomchi()},
        component: () => import('@/pages/FinishInom.vue')
    }
]

export default createRouter({
    history: createWebHistory(),
    routes
})