<template>
    <div class="fixed">

        <router-link class="btn" to="">
            <i class="fas fa-bell"></i>
        </router-link>


        <form id="app-cover">
            <div id="select-box">
                <input type="checkbox" id="options-view-button">
                <div id="select-button">
                    <div id="selected-value">
                        <i class="fas fa-user" style="margin-right: 3%;"></i>
                        <span>{{ getUserName }}</span>
                    </div>
                </div>
                <div id="options">
                    <div class="option">
                        <router-link to="/cabinet-page" class="option" title="Kabinetga o'tish">
                            <i class="fas fa-gear"></i>
                            <span class="label">Sozlamalar</span>
                            <span class="opt-val">Sozlamalar</span>
                        </router-link>
                    </div>
                    <div class="option">
                        <div @click.prevent="logOutUser" title="Tizimdan chiqish" class="option">
                            <i class="fas fa-arrow-right-from-bracket"></i>
                            <span class="label">Tizimdan chiqish</span>
                            <span class="opt-val">Tizimdan chiqish</span>
                        </div>
                    </div>
                    <div id="option-bg"></div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";

export default {
    name: "CabinetFixedPage",
    methods: {
        ...mapActions(['logOut']),
        logOutUser() {
            this.logOut()
            this.$router.push('/login')
        }
    },
    computed: {
        ...mapGetters(['getUserName'])
    }
}
</script>

<style scoped>
.fixed{
    display: flex;
    justify-content: end;
}
#app-cover {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    height: 42px;
    width: 200px;
}

#select-button {
    position: relative;
    height: 16px;
    padding: 12px 14px;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
}

#options-view-button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

#selected-value {
    font-size: 16px;
    line-height: 1;
    margin-right: 26px;
}

.option i {
    width: 16px;
    height: 16px;
}

.option,
.label {
    color: #2d3667;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
}



#options {
    position: absolute;
    top: 42px;
    right: 0;
    left: 0;

    margin: 0 auto;
    background-color: #fff;
    border-radius: 4px;
}

#options-view-button:checked~#options {
    border: 1px solid #e2eded;
    border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
}

.option {
    position: relative;
    line-height: 1;
    transition: 0.3s ease all;
    z-index: 2;
}

.option i {
    position: absolute;
    left: 14px;
    padding: 0;
    display: none;
}

#options-view-button:checked~#options .option i {
    display: block;
    padding: 12px 0;
}

.label {
    display: none;
    padding: 0;
    margin-left: 27px;
}

#options-view-button:checked~#options .label {
    display: block;
    padding: 12px 14px;
}

@keyframes moveup {
    0% {
        bottom: -25px;
        opacity: 0;
    }

    100% {
        bottom: 0;
        opacity: 1;
    }
}

@keyframes movedown {
    0% {
        top: -25px;
        opacity: 0;
    }

    100% {
        top: 0;
        opacity: 1;
    }
}

.label {
    transition: 0.3s ease all;
}

.opt-val {
    position: absolute;
    left: 14px;
    width: 217px;
    height: 21px;
    opacity: 0;
    background-color: #fff;
    transform: scale(0);
}

.option input[type="radio"]:checked~.opt-val {
    opacity: 1;
    transform: scale(1);
}

.option input[type="radio"]:checked~i {
    top: 0;
    bottom: auto;
    opacity: 1;
    animation: unset;
}

.option input[type="radio"]:checked~i,
.option input[type="radio"]:checked~.label {
    color: #fff;
}

.option input[type="radio"]:checked~.label:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

#options-view-button:not(:checked)~#options .option input[type="radio"]:checked~.opt-val {
    top: -30px;
}

.option:nth-child(1) input[type="radio"]:checked~.label:before {
    background-color: #000;
    border-radius: 4px 4px 0 0;
}

.option:nth-child(1) input[type="radio"]:checked~.opt-val {
    top: -31px;
}

.option:nth-child(2) input[type="radio"]:checked~.label:before {
    background-color: #ea4c89;
}

.option:nth-child(2) input[type="radio"]:checked~.opt-val {
    top: -71px;
}

.option:nth-child(3) input[type="radio"]:checked~.label:before {
    background-color: #0057ff;
}

.option:nth-child(3) input[type="radio"]:checked~.opt-val {
    top: -111px;
}

.option:nth-child(4) input[type="radio"]:checked~.label:before {
    background-color: #32c766;
}

.option:nth-child(4) input[type="radio"]:checked~.opt-val {
    top: -151px;
}

.option:nth-child(5) input[type="radio"]:checked~.label:before {
    background-color: #f48024;
}

.option:nth-child(5) input[type="radio"]:checked~.opt-val {
    top: -191px;
}

.option:nth-child(6) input[type="radio"]:checked~.label:before {
    background-color: #006400;
    border-radius: 0 0 4px 4px;
}

.option:nth-child(6) input[type="radio"]:checked~.opt-val {
    top: -231px;
}

.option .fa-codepen {
    color: #000;
}

.option .fa-dribbble {
    color: #ea4c89;
}

.option .fa-behance {
    color: #0057ff;
}

.option .fa-hackerrank {
    color: #32c766;
}

.option .fa-stack-overflow {
    color: #f48024;
}

.option .fa-free-code-camp {
    color: #006400;
}

#option-bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 40px;
    transition: 0.3s ease all;
    z-index: 1;
    display: none;
}

#options-view-button:checked~#options #option-bg {
    display: block;
}

.option:hover .label {
    color: #fff;
}

.option:nth-child(1):hover~#option-bg {
    top: 0;
    background-color: #32c766;
    border-radius: 4px 4px 0 0;
}

.option:nth-child(2):hover~#option-bg {
    top: 40px;
    background-color: #ea4c89;
}

.option:nth-child(3):hover~#option-bg {
    top: 80px;
    background-color: #0057ff;
}

.option:nth-child(4):hover~#option-bg {
    top: 120px;
    background-color: #32c766;
}

.option:nth-child(5):hover~#option-bg {
    top: 160px;
    background-color: #f48024;
}

.option:nth-child(6):hover~#option-bg {
    top: 200px;
    background-color: #006400;
    border-radius: 0 0 4px 4px;
}

/* select end */
.fas,
span {
    color: #5A1AAC;
}

button {
    color: #5A1AAC;
    border: none;
}
</style>