import axios from './axios'

export default {
    actions: {
        postPurchases(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('purchases', data)
                    .then(() => {
                        resolve()
                        context.dispatch('fetchPurchases')
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchPurchases(context, data = {}) {
            let url = 'purchases?order[id]=desc'

            if (data['page']) {
                url += '&page=' + data['page']
            } else {
                url += '&page=1'
            }

            if (data['supplier']) {
                url += '&supplier=' + data['supplier']
            }

            if (data['isDebtValue'] || data['isDebtValue'] === false) {
                url += '&exists[transaction]=' + data['isDebtValue']
            }

            if (data.name) {
                url += '&supplier.name=' + data.name
            }

            if (data.proName) {
                url += '&productPurchases.product.name=' + data.proName
            }

            return new Promise((resolve, reject) => {
                axios
                    .get(url)
                    .then((response) => {
                        let purchases = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems'],
                        }

                        context.commit('updatePurchases', purchases)
                        resolve()
                    })
                    .catch(() => {

                        reject()
                    })

            })
        },
        fetchPurchase(context, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get('purchases/' + id)
                    .then((response) => {
                        context.commit('updatePurchase', response.data)
                        resolve(response.data)
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        cancelPurchase(context, id) {
            return new Promise((resolve, reject) => {
                axios
                    .put('purchases/' + id + '/cancel', {})
                    .then(() => {
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        editPurchase(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('purchases/edit_purchase', data)
                    .then(() => {
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
    },
    mutations: {
        updatePurchases(state, purchases) {
            state.purchases = purchases
        },
        updatePurchase(state, purchase) {
            state.purchase = purchase
        }
    },
    state: {
        purchases: {
            models: [],
            totalItems: 0,
        },
        purchase: null
    },
    getters: {
        getPurchases(state) {
            return state.purchases.models
        },
        getPurchaseTotalItems(state) {
            return state.purchases.totalItems
        }
    }
}