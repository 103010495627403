import axios from './axios'
import router from "@/plugins/router";

export default {
    actions: {
        postCustomer(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('customers', data)
                    .then(() => {
                        resolve()
                        context.dispatch('fetchCustomers')
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        payCustomer(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put('customers/' + data.id + '/pay', data)
                    .then(() => {
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        putCustomer(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put('customers/' + data.id, data)
                    .then(() => {
                        resolve()
                        context.dispatch('fetchCustomers')
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchCustomers(context, data = {}) {
            let url = 'customers?'

            if (data.page) {
                url += 'page=' + data.page + '&'
            } else {
                url += 'pagination=false&'
            }

            if (!(router.currentRoute['_value'].path === '/orderPage' || router.currentRoute['_value'].path === '/comers-page')) {
                url += 'order[id]=desc&isService=' + (data.isService ?? false)
            }

            if (data.isOneTime === true || data.isOneTime === false) {
                url += '&isOneTime=' + data.isOneTime
            }

            if (data.name) {
                if (data.name.match(/^\d+$/)) {
                    url += '&telephone=' + data.name
                } else {
                    url += '&name=' + data.name
                }
            }

            return new Promise((resolve, reject) => {
                axios
                    .get(url)
                    .then((response) => {
                        let customers = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems'],
                        }

                        context.commit('updateCustomers', customers)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchCustomer(context, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get('customers/' + id)
                    .then((response) => {
                        context.commit('updateCustomer', response.data)
                        resolve(response.data)
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchCustomerDebt(context, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get('customers/' + id + '/debt')
                    .then((response) => {
                        context.commit('updateCustomerDebt', response.data.amount)
                        resolve(response.data)
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        cancelCustomer(context, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete('customers/' + id)
                    .then(() => {
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
    },
    mutations: {
        updateCustomers(state, customers) {
            state.customers = customers
        },
        updateCustomer(state, customer) {
            state.customer = customer
        },
        updateCustomerDebt(state, amount) {
            state.customerDebt = amount
        }
    },
    state: {
        customers: {
            models: [],
            totalItems: 0,
        },
        customer: null,
        customerDebt: 0
    },
    getters: {
        getCustomers(state) {
            return state.customers.models
        },
        getCustomerTotalItems(state) {
            return state.customers.totalItems
        },
        getCustomer(state) {
            return state.customer
        },
        getCustomerDebt(state) {
            return state.customerDebt
        },
    }
}