import axios from './axios'

export default {
    actions: {
        postSupplier(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('suppliers', data)
                    .then(() => {
                        resolve()
                        context.dispatch('fetchSuppliers')
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        paySupplier(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put('suppliers/' + data.id + '/pay', data)
                    .then(() => {
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        putSupplier(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put('suppliers/' + data.id, data)
                    .then(() => {
                        resolve()
                        context.dispatch('fetchSuppliers')
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchSuppliers(context, data = {}) {
            let url = 'suppliers?order[id]=desc'

            if (data.name) {
                if (data.name.match(/^\d+$/)) {
                    url += '&telephone=' + data.name
                } else {
                    url += '&name=' + data.name
                }
            }

            return new Promise((resolve, reject) => {
                axios
                    .get(url)
                    .then((response) => {
                        let suppliers = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems'],
                        }

                        context.commit('updateSuppliers', suppliers)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchSupplier(context, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get('suppliers/' + id)
                    .then((response) => {
                        context.commit('updateSupplier', response.data)
                        resolve(response.data)
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchSupplierFee(context, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get('suppliers/' + id + '/debt')
                    .then((response) => {
                        context.commit('updateSupplierFee', response.data.amount)
                        resolve(response.data)
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        cancelSupplier(context, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete('suppliers/' + id)
                    .then(() => {
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
    },
    mutations: {
        updateSuppliers(state, suppliers) {
            state.suppliers = suppliers
        },
        updateSupplier(state, supplier) {
            state.supplier = supplier
        },
        updateSupplierFee(state, amount) {
            state.supplierFee = amount
        }
    },
    state: {
        suppliers: {
            models: [],
            totalItems: 0,
        },
        supplier: null,
        supplierFee: 0
    },
    getters: {
        getSuppliers(state) {
            return state.suppliers.models
        },
        getSupplier(state) {
            return state.supplier
        },
        getSupplierFee(state) {
            return state.supplierFee
        },

    }
}