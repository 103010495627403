import axios from './axios'

export default {
    actions: {
        postPositions(context, name) {
            return new Promise((resolve, reject) => {
                axios
                    .post('employee_positions', {name: name})
                    .then(() => {
                        resolve()
                        context.dispatch('fetchPositions')
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchPositions(context) {
            return new Promise((resolve, reject) => {
                axios
                    .get('employee_positions')
                    .then((response) => {
                        let positions = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems'],
                        }

                        context.commit('updatePositions', positions)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })

            })
        }
    },
    mutations: {
        updatePositions(state, positions) {
            state.positions = positions
        }
    },
    state: {
        positions: {
            models: [],
            totalItems: 0,
        }
    },
    getters: {
        getPositions(state) {
            return state.positions.models
        }
    }
}