import axios from './axios'

export default {
    actions: {
        postShablon(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('shablons', data)
                    .then(() => {
                        resolve()
                        context.dispatch('fetchShablons')
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchShablons(context, page = 1) {
            return new Promise((resolve, reject) => {
                axios
                    .get('shablons?page=' + page)
                    .then((response) => {
                        let shablons = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems'],
                        }

                        context.commit('updateShablons', shablons)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchShablon(context, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get('shablons/' + id)
                    .then((response) => {
                        context.commit('updateShablon', response.data)
                        resolve(response.data)
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        putShablon(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put('shablons/' + data.id, data)
                    .then((response) => {
                        context.commit('updateShablon', response.data)
                        resolve(response.data)
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        deleteShablon(context, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete('shablons/' + id)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch(() => {
                        reject()
                    })

            })
        }
    },
    mutations: {
        updateShablons(state, shablons) {
            state.shablons = shablons
        },
        updateShablon(state, shablon) {
            state.shablon = shablon
        }
    },
    state: {
        shablons: {
            models: [],
            totalItems: 0,
        },
        shablon: null
    },
    getters: {
        getShablons(state) {
            return state.shablons.models
        },
        getShablonTotalItems(state) {
            return state.shablons.totalItems
        }
    }
}