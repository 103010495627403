import axios from './axios'
import router from "@/plugins/router";

export default {
    actions: {
        postSale(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('sales', data)
                    .then(() => {
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        priceSale(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put('sales/' + data.id + '/pricing', data)
                    .then(() => {
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        editSale(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('sales/edit_sale', data)
                    .then(() => {
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        putStatusSale(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put('sales/' + data.id + '/status', data)
                    .then((response) => {
                        context.commit('updateSale', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        brokenGlass(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('sales/broken_glass', data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        cancelSale(context, id) {
            return new Promise((resolve, reject) => {
                axios
                    .put('sales/' + id + '/cancel', {})
                    .then(() => {
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        fetchSales(context, data) {
            let url = 'sales'

            if (data.type === 'progress') {
                if (data.val === -1 || data.val === 0 || data.val === -2 || data.val === -3 || data.val === -4 || data.val === -5 || data.val === -44 || data.val === -45 || data.val === -100) {
                    url += '/progress?type=' + data.val
                } else {
                    if (router.currentRoute['_value'].path === '/buyurtma-page') {
                        url += '?order[id]=desc&orderStatus[]=5&orderStatus[]=6'
                    } else if (router.currentRoute['_value'].path === '/start-list') {
                        url += '?order[id]=desc&orderStatus[]=1&orderStatus[]=2'
                    } else {
                        url += '?order[id]=desc&orderStatus=' + data.val
                    }
                }
            } else if (data.type === 'start-order') {
                if (data.val === 1) {
                    url += '?order[updatedAt]=desc&serviceStatus[]=1&serviceStatus[]=2'
                } else {
                    url += '?order[updatedAt]=desc&orderStatus[]=1&orderStatus[]=2'
                }
            } else if (data.type === 'finish-list') {
                url += '?order[parent]=desc&order[id]=desc&orderStatus[]=3&orderStatus[]=2'
            } else if (data.type === 'finish-inom') {
                url += '?order[parent]=desc&order[id]=desc&serviceStatus[]=3&serviceStatus[]=2'
            } else {
                url += '?order[id]=desc'
            }

            if (
                router.currentRoute['_value'].path !== '/pricing-order' &&
                router.currentRoute['_value'].path !== '/orders-page' &&
                router.currentRoute['_value'].path !== '/clientplus' &&
                router.currentRoute['_value'].path !== '/buyurtma-page'
            ) {
                url += '&isOrder=true'
            }

            if (data.type === 'pricing') {
                url += '&orderStatus=0'
            }

            if (data['page']) {
                url += '&page=' + data['page']
            } else {
                url += '&page=1'
            }

            if (data['customer']) {
                url += '&customer=' + data['customer']
            }

            if (data['isDebtValue'] && data['isDebtValue'] !== null) {
                url += '&exists[transaction]=' + data['isDebtValue']
            }

            if (data['name']) {
                // check url exist 'sales' word
                if (data.name.match(/^\d+$/)) {
                    url += '&customer.telephone=' + data.name
                } else {
                    url += '&customer.name=' + data.name
                }
            }

            if (data.orderStatus) {
                url += '&orderStatus=' + data.orderStatus
            }

            if (data['id']) {
                url += '&id=' + data['id']
            }

            return new Promise((resolve, reject) => {
                axios
                    .get(url)
                    .then((response) => {
                        let sales = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems'],
                        }

                        if (data.type === 'start-order') {
                            context.commit('updateSale', response.data['hydra:member'][0])
                        } else {
                            context.commit('updateSales', sales)
                        }

                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchSale(context, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get('sales/' + id)
                    .then((response) => {
                        context.commit('updateSale', response.data)
                        resolve(response.data)
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchImage(context, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get('media_objects/' + id + '/image')
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchStatistics(context, {from, to}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('sales/statistics?from=' + from + '&to=' + to)
                    .then((response) => {
                        context.commit('updateStatistics', response.data)
                        resolve(response.data)
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },
        fetchStatisticSales(context, {from, to, type}) {
            return new Promise((resolve, reject) => {
                let url = 'sales?'

                url += 'createdAt[strictly_after]=' + from + 'T00:00:00&'
                url += 'createdAt[strictly_before]=' + to + 'T23:59:59'

                if (type === 3) {
                    url += '&exists[transaction]=false'
                } else {
                    url += '&isOrder=true'
                }

                axios
                    .get(url)
                    .then((response) => {
                        let sales = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems'],
                        }

                        context.commit('updateSales', sales)

                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchIncomeHistory(context, {from, to, type}) {
            return new Promise((resolve, reject) => {
                let url = 'sales/income-history?from=' + from + '&to=' + to;

                if (type === 4) {
                    url += '&debt=true'
                }

                axios
                    .get(url)
                    .then((response) => {
                        context.commit('updateStatisticHistory', response.data)
                        resolve(response.data)
                    })
                    .catch(() => {
                        reject()
                    })
            })
        }
    },
    mutations: {
        updateSales(state, sales) {
            state.sales = sales
        },
        updateSale(state, sale) {
            state.sale = sale
        },
        updateStatistics(state, statistics) {
            state.statistics = statistics
        },
        updateStatisticHistory(state, history) {
            state.history = history
        }
    },
    state: {
        sales: {
            models: [],
            totalItems: 0,
        },
        sale: {
            saleProduct: []
        },
        statistics: {
            plus: 0,
            minus: 0,
            glass: 0,
            benefit: 0
        },
        history: []
    },
    getters: {
        getSales(state) {
            return state.sales.models
        },
        getSaleTotalItems(state) {
            return state.sales.totalItems
        },
        getSale(state) {
            return state.sale
        },
        getStatistics(state) {
            return state.statistics
        },
        getHistory(state) {
            return state.history
        },
    }
}