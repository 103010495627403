import axios from './axios'

export default {
    actions: {
        postPerson(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('people/employee', data)
                    .then(() => {
                        resolve()
                        context.dispatch('fetchPersons')
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        putPerson(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .put('people/' + data.id + '/employee', data)
                    .then(() => {
                        resolve()
                        context.dispatch('fetchPersons')
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchPersons(context) {
            return new Promise((resolve, reject) => {
                axios
                    .get('people?exists[employeePosition]=true&order[id]=desc')
                    .then((response) => {
                        let persons = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems'],
                        }

                        context.commit('updatePersons', persons)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchPerson(context, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get('people/' + id)
                    .then((response) => {
                        context.commit('updatePerson', response.data)
                        resolve(response.data)
                    })
                    .catch(() => {
                        reject()
                    })

            })
        }
    },
    mutations: {
        updatePersons(state, persons) {
            state.persons = persons
        },
        updatePerson(state, person) {
            state.person = person
        }
    },
    state: {
        persons: {
            models: [],
            totalItems: 0,
        },
        person: null
    },
    getters: {
        getPersons(state) {
            return state.persons.models
        }
    }
}