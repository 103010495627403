import axios from './axios'

export default {
    actions: {
        postIOCome(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('income_and_outcomes', data)
                    .then(() => {
                        resolve()
                        context.dispatch('fetchIOComes')
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchIOComes(context, data = null) {
            return new Promise((resolve, reject) => {
                let url = 'income_and_outcomes?'

                if (data) {
                    if (data.isIncome !== null) {
                        url += '&isIncome=' + data.isIncome + '&'
                    }

                    if (data.from) {
                        url += 'createdAt[strictly_after]=' + data.from + 'T00:00:00&'
                    }

                    if (data.to) {
                        url += 'createdAt[strictly_before]=' + data.to + 'T23:59:59'
                    }
                }

                axios
                    .get(url)
                    .then((response) => {
                        let iocomes = {
                            models: response.data['hydra:member'],
                            totalItems: response.data['hydra:totalItems'],
                        }

                        context.commit('updateIOComes', iocomes)
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })

            })
        },
        fetchIOCome(context, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get('income_and_outcomes/' + id)
                    .then((response) => {
                        context.commit('updateIOCome', response.data)
                        resolve(response.data)
                    })
                    .catch(() => {
                        reject()
                    })

            })
        }
    },
    mutations: {
        updateIOComes(state, iocomes) {
            state.iocomes = iocomes
        },
        updateIOCome(state, iocome) {
            state.iocome = iocome
        }
    },
    state: {
        iocomes: {
            models: [],
            totalItems: 0,
        },
        iocome: null
    },
    getters: {
        getIOComes(state) {
            return state.iocomes.models
        }
    }
}