import axios from "@/plugins/vuex/axios";

export default {
    actions: {
        pushImage(context, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('media_objects', data)
                    .then((response) => {
                        context.commit('updatePushImage', response.data['hydra:member'])
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })

            })
        }
    },
    mutations: {
        updatePushImage(state, data) {
            return state.pushImageState = data
        }
    },
    state: {
        pushImageState: {
            id: null,
        }
    },
    getters: {
        getProductImageId(state) {
            return state.pushImageState.id
        }
    }
}
